import { useState } from 'react';
import { GetStaticProps, GetStaticPropsResult } from 'next';
import dynamic from 'next/dynamic';
import FsLightbox from 'fslightbox-react';

import { Movie, Cinema, MovieStatus, MovieSingle, CinemaSingle } from '@app/types';
import Layout from '@app/layouts/page-default';
import HomeMessage from '@app/components/home-message';
import CinemaCard from '@app/components/cinema-card';
import Seo from '@app/components/seo';
import { firebase } from '@app/config/fire-conf';

type HomeProps = {
  moviesStrObjs: string[];
  cinemasStrObjs: string[];
};

const MovieDetailSlider = dynamic(() => import('../components/movie-detail-slider'), {
  ssr: false,
});
const MovieCardSlider = dynamic(() => import('../components/movie-card-slider'), { ssr: false });

export default function Home({ moviesStrObjs, cinemasStrObjs }: HomeProps): JSX.Element {
  const movies: Movie[] = moviesStrObjs.map((movieStrObj) => {
    const movieSingle = JSON.parse(movieStrObj) as MovieSingle;
    return {
      ...movieSingle,
      releaseDate: firebase.firestore.Timestamp.fromMillis(movieSingle.releaseDate.seconds * 1000),
      lastUpdatedAt: firebase.firestore.Timestamp.fromMillis(
        movieSingle.lastUpdatedAt.seconds * 1000,
      ),
      premiereDate: movieSingle.premiereDate
        ? firebase.firestore.Timestamp.fromMillis(movieSingle.premiereDate.seconds * 1000)
        : undefined,
    };
  });
  const cinemas: Cinema[] = cinemasStrObjs.map((cinemaStrObj) => {
    const cinemaSingle = JSON.parse(cinemaStrObj) as CinemaSingle;
    return {
      ...cinemaSingle,
      lastUpdatedAt: firebase.firestore.Timestamp.fromMillis(
        cinemaSingle.lastUpdatedAt.seconds * 1000,
      ),
    };
  });
  const [toggler, setToggler] = useState(false);
  const [trailer, setTrailer] = useState('');

  const onWatchTrailer = (trailerUrl: string) => {
    setTrailer(trailerUrl);
    setToggler(!toggler);
  };

  return (
    <Layout>
      <Seo />
      <MovieDetailSlider movies={movies.filter((movie) => movie.featured)} cinemas={cinemas} />
      <div className="flex flex-col">
        <MovieCardSlider
          movies={movies.filter((movie) => movie.status === 'active')}
          title="Now Showing"
          link="/movies#now-showing"
          onWatchTrailer={onWatchTrailer}
        />
        <MovieCardSlider
          movies={movies.filter((movie) => movie.status === 'coming-soon')}
          title="Coming Soon"
          link="/movies#coming-soon"
          onWatchTrailer={onWatchTrailer}
        />
      </div>

      <HomeMessage title="We’re doing our part to help keep you safe." />

      <div className="kcc-container">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-6 pb-14 px-8">
          {cinemas.map((cinema) => (
            <CinemaCard key={cinema.label} cinemaName={cinema.label} cinema={cinema} />
          ))}
        </div>
      </div>
      <FsLightbox toggler={toggler} sources={[trailer]} />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const moviesQuerySnap = await firebase
    .firestore()
    .collection('movies')
    .where('status', '!=', MovieStatus.archive)
    .get();

  const moviesStrObjs: string[] = [];
  moviesQuerySnap.forEach((movie) => {
    const movieObj = { ...(movie.data() as Movie), id: movie.id };
    const movieStrObj = JSON.stringify(movieObj);
    moviesStrObjs.push(movieStrObj);
  });

  const cinemasQuerySnap = await firebase.firestore().collection('cinemas').get();

  const cinemasStrObjs: string[] = [];
  cinemasQuerySnap.forEach((cinema) => {
    const cinemaObj = { ...cinema.data(), id: cinema.id };
    const cinemaStrObj = JSON.stringify(cinemaObj);
    cinemasStrObjs.push(cinemaStrObj);
  });

  const returnMovies: GetStaticPropsResult<HomeProps> = {
    props: {
      moviesStrObjs,
      cinemasStrObjs,
    },
    revalidate: 60,
  };

  return returnMovies;
};
