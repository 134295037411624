import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

export default function SEO(props: NextSeoProps): JSX.Element {
  const DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN;
  const router = useRouter();
  const pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
  ]);
  const canonicalURL = DOMAIN + router.asPath.substring(0, pathSliceLength);
  return <NextSeo canonical={canonicalURL} {...props} />;
}
