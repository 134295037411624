import React from 'react';
import Link from 'next/link';

import { Cinema } from '@app/types/cinema';
import Image from '@app/components/image';

import { CinemaCardStyled } from './cinema-card-styled';

type CinemaCardProps = {
  cinemaName?: string;
  cinema?: Cinema;
};

const CinemaCard = ({ cinemaName = '', cinema }: CinemaCardProps): JSX.Element => {
  return (
    <CinemaCardStyled className={cinemaName}>
      <h4>KCC Multiplex</h4>
      <h6>{cinemaName}</h6>
      <ul>
        {cinema?.features[0] && (
          <li>
            <Image
              src="/assets/icons/cinema-dolby-atmos.svg"
              width={40}
              height={40}
              alt="Cinema Sound System"
            />
            <span className="d-b ml-3">{cinema?.features[0]}</span>
          </li>
        )}
        {cinema?.features[1] && (
          <li>
            <Image src="/assets/icons/cinema-crowd.svg" width={40} height={40} alt="Cinema Crowd" />
            <span className="d-b ml-3">{cinema?.features[1]}</span>
          </li>
        )}
      </ul>
      <div className="card-footer flex justify-between items-center mt-8">
        <Link href={`/buy-tickets/?cinema=${cinema?.id}`}>
          <a className="btn btn--primary">View Showtimes</a>
        </Link>
        <div>
          {cinema?.id === 'c1' ? (
            <Image src="/assets/icons/dolby-atmos.svg" width={94} height={24} alt="Dolby Atmos" />
          ) : (
            <Image
              src="/assets/images/dolby-digital-logo-dolby-surround-7-1_white.png"
              layout="intrinsic"
              width={105}
              height={24}
              alt="Dolby Digital"
            />
          )}
        </div>
      </div>
    </CinemaCardStyled>
  );
};

export default CinemaCard;
