import styled from 'styled-components';
import tw from 'twin.macro';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const CinemaCardStyled = styled.div`
  ${tw`text-white p-10 px-5 pb-5 mb-16 lg:p-10 lg:mt-0 rounded-sm relative`}

  h4 {
    ${tw`text-white font-bold mb-6`}
    font-size: 28px;
  }

  h6 {
    ${tw`text-black text-7xl text-white absolute`}
    font-weight: 800;
    text-shadow: 0px 2px 8px #00000080;
    right: -16px;
    top: -40px;

    @media (min-width: 1024px) {
      font-size: 140px;
      right: -16px;
      top: -55px;
    }
  }

  &.C1 {
    background: transparent radial-gradient(closest-side at 83% 85%, #c34949 0%, #631e1e 500%) 0% 0%
      no-repeat padding-box;
  }
  &.C2 {
    background: transparent radial-gradient(closest-side at 83% 85%, #7e684e 0%, #473f2d 500%) 0% 0%
      no-repeat padding-box;
  }
  &.C3 {
    background: transparent radial-gradient(closest-side at 83% 85%, #575757 0%, #2f2f2f 500%) 0% 0%
      no-repeat padding-box;
  }

  ul {
    ${tw`mb-20`}

    li {
      ${tw`flex items-center text-xl mb-5 font-bold`}

      svg {
        ${tw`w-10 mr-4`}
      }
    }
  }

  .card-footer {
    flex-direction: column-reverse;
    align-items: flex-start;

    > div {
      margin-bottom: 20px;
    }

    a {
      display: block;
      width: 100%;
    }

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      flex-direction: row;
      align-items: center;

      > div {
        margin-bottom: 0;
      }

      a {
        display: block;
        width: auto;
      }
    }
  }
`;
