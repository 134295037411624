import React from 'react';
import Link from 'next/link';

import Image from '@app/components/image';

import { HomeMessageStyled } from './home-message-styled';

type HomeMessageProps = {
  title?: string;
};

const HomeMessage = ({ title = '' }: HomeMessageProps): JSX.Element => {
  return (
    <HomeMessageStyled>
      <div className="background-image">
        <Image
          // src="/assets/images/felix-mooneeram-unsplash.jpg"
          src="https://firebasestorage.googleapis.com/v0/b/kandy-city-centre.appspot.com/o/public%2Fsite%2Fassets%2Fimages%2Ffelix-mooneeram-unsplash.jpg?alt=media&token=844e100b-207c-4b2f-a1fe-11f319011bca"
          layout="fill"
          objectFit="cover"
          alt="home message background"
        />
      </div>
      <div className="content-wrapper">
        <div className="max-w-screen-sm">
          <h2>{title}</h2>
          <p>
            With cinemas now open, your safety and comfort is our priority. We’re committed to
            keeping our cinemas, employees and patrons in line with prevailing healthy and safety
            guidelines issued by local authorities, as well as our own expert-backed suggestions. We
            humbly request you familiarize yourself with our guidelines and check back often for
            updates, in order to have an uninterrupted cinematic experience at KCC Multiplex.
          </p>
          <h3>Check out our COVID-19 health and safety guidelines:</h3>
          <Link href="/covid-19-guidelines/en/">
            <a className="btn--blue">English</a>
          </Link>
          <Link href="/covid-19-guidelines/si/">
            <a className="ml-5 btn--blue">සිංහල</a>
          </Link>
          <Link href="/covid-19-guidelines/ta/">
            <a className="ml-5 btn--blue">தமிழ்</a>
          </Link>
        </div>
      </div>
    </HomeMessageStyled>
  );
};

export default HomeMessage;
