import styled from 'styled-components';
import tw from 'twin.macro';

export const HomeMessageStyled = styled.div`
  ${tw`bg-no-repeat relative bg-center pt-64 pb-56  my-20 overflow-hidden`};
  /* background-image: url('/assets/images/felix-mooneeram-unsplash.png'); */

  .background-image {
    ${tw`absolute w-full h-full inset-0 z-0`};
    top: -5rem;

    &:after {
      ${tw`bg-gradient-to-r from-black z-10`};
      ${tw`block absolute w-full bottom-0`};
      content: '';
      height: 100%;
    }

    &:before {
      ${tw`bg-gradient-to-t from-black z-20`};
      ${tw`block absolute w-full bottom-0`};
      content: '';
      height: 80%;
    }
  }

  .content-wrapper {
    ${tw`text-white p-1 px-8 items-center m-auto z-10 relative`}
    max-width: 1360px;

    h2 {
      ${tw`text-white text-3xl lg:text-5xl lg:leading-tight font-bold pb-6 w-full`};
    }

    h3 {
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
      margin-top: 8px;
    }

    p {
      ${tw`text-base md:text-xl text-white font-normal mb-4 leading-7`};
      max-width: 700px;
    }
  }
`;
